<template>
  <CModalBase name="add-vendor" size="lg">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-2">
          <h2 class="text-3xl font-semibold">
            {{ $t('Vendors.Vendors invitation') }}
          </h2>
          <div class="text-gray-400 text-xs text-center">
            {{ $t('Vendors.Add vendor to your database') }}
          </div>
        </div>
        <div class="flex flex-row items-start space-x-4">
          <div class="flex-grow flex flex-col space-y-4">
            <span class="text-lg font-semibold">{{ $t('Vendors.Individual Artist') }}</span>
            <div class="flex flex-col space-y-3">
              <CRadio
                value="individual-single"
                name="state"
                label-class="items-center"
                :check-value="state"
                @input="($event) => (state = $event)"
              >
                <span class="text-sm leading-5">{{ $t('Vendors.Single Artist') }}</span>
              </CRadio>
              <CRadio
                :disabled="true"
                value="individual-batch"
                name="state"
                label-class="items-center"
                :check-value="state"
                @input="($event) => (state = $event)"
              >
                <span class="text-sm leading-5">{{ $t('Vendors.Batch Invitation') }}</span>
              </CRadio>
            </div>
          </div>
          <div class="flex-grow flex flex-col space-y-4">
            <span class="text-lg font-semibold">{{ $t('Vendors.Studio') }}</span>
            <div class="flex flex-col space-y-3">
              <CRadio
                value="studio-single"
                name="state"
                label-class="items-center"
                :check-value="state"
                @input="($event) => (state = $event)"
              >
                <span class="text-sm leading-5">{{ $t('Vendors.Single Studio') }}</span>
              </CRadio>
              <CRadio
                :disabled="true"
                value="studio-batch"
                name="state"
                label-class="items-center"
                :check-value="state"
                @input="($event) => (state = $event)"
              >
                <span class="text-sm leading-5">{{ $t('Vendors.Batch Invitation') }}</span>
              </CRadio>
            </div>
          </div>
        </div>
        <div class="flex justify-end space-x-3">
          <CButton type="outline" accent="secondary" class="text-gray-500" @click="hide">
            {{ $t('Cancel') }}
          </CButton>
          <CButton class="text-white" @click="onInviteClick({ hide })">
            {{ $t('Invite') }}
          </CButton>
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CRadio from 'devotedcg-ui-components/CRadio.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';

export default {
  name: 'ModalAddVendor',
  components: {
    CModalBase,
    CButton,
    CRadio,
  },
  data() {
    return {
      state: 'individual-single',
    };
  },
  methods: {
    onInviteClick({ hide }) {
      switch (this.state) {
        case 'individual-single':
          this.$router.push({
            name: 'Dashboard.Vendors.Database.Invite',
            params: {
              entity: 'artist',
            },
          });
          hide();
          break;
        case 'studio-single':
          this.$router.push({
            name: 'Dashboard.Vendors.Database.Invite',
            params: {
              entity: 'studio',
            },
          });
          hide();
          break;
        default:
      }
    },
  },
};
</script>
