var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-black-100 text-white flex flex-col p-5 space-y-8"},[_c('Tippy',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var shown = ref.shown;
return [_c('div',{staticClass:"rounded-15 bg-gray-600 px-4 py-3 cursor-pointer"},[_c('div',{staticClass:"flex flex-row items-center space-x-4"},[_c('div',{staticClass:"flex flex-col flex-grow"},[(_vm.user)?[_c('span',{staticClass:"text-sm font-semibold"},[_vm._v(" "+_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)+" ")]),_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.getUserRole)+" ")])]:_vm._e()],2),_c('div',{staticClass:"w-8 h-8 rounded-full overflow-hidden flex-shrink-0"},[_c('div',{staticClass:"w-full h-full bg-opacity-25 bg-white"})]),_c('div',[_c('IconDropdownDown',{staticClass:"transform transition-transform duration-100",class:{
                'rotate-180': shown,
              }})],1)])])]}},{key:"default",fn:function(ref){
              var hide = ref.hide;
return [_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"flex flex-col text-primary text-sm leading-5"},[_c('span',{staticClass:"px-12 py-1 cursor-pointer",on:{"click":function () {
                hide();
                _vm.toUser();
              }}},[_vm._v(" "+_vm._s(_vm.$t('Menu.Settings'))+" ")]),_c('span',{staticClass:"px-12 py-1 text-red-100 cursor-pointer",on:{"click":function () {
                hide();
                _vm.onLogout();
              }}},[_vm._v(" "+_vm._s(_vm.$t('Menu.Logout'))+" ")])])])]}}])}),_c('div',{staticClass:"flex flex-col space-y-8"},_vm._l((_vm._payload),function(group,groupIndex){return _c('div',{key:groupIndex},[(!!group.parentLink)?_c('router-link',{key:groupIndex,staticClass:"flex flex-col space-y-4 text-white text-sm menu-parent-link",attrs:{"to":{ name: 'Dashboard.Chat' },"exact":""}},[_c('div',{staticClass:"flex items-center justify-between"},[_c('span',[_vm._v(_vm._s(group.group))]),_c('CTag',{staticClass:"menu__tag",attrs:{"accent":"secondary","rounded":""}},[_vm._v(" "+_vm._s(group.addon)+" ")])],1),_c('div',{staticClass:"separator"})]):_c('MenuGroup',{attrs:{"payload":group}})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }