var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{attrs:{"name":"user-invitation"},on:{"hide":_vm.onHide},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"flex flex-col space-y-2"},[_c('h2',{staticClass:"text-3xl font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('Invitation.Admin invitation'))+" ")]),_c('div',{staticClass:"text-gray-400 text-xs text-center"},[_vm._v(" Send invitation to admin ")])]),_c('div',{staticClass:"separator my-10"}),_c('div',{staticClass:"flex flex-col space-y-6"},[_c('CInput',{attrs:{"placeholder":"First Name *","error-message":_vm.$errorMessage(_vm.$v.payload.firstName, 'firstName'),"error-show":!!_vm.$errorMessage(_vm.$v.payload.firstName, 'firstName')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('IconProfile')]},proxy:true}],null,true),model:{value:(_vm.payload.firstName),callback:function ($$v) {_vm.$set(_vm.payload, "firstName", $$v)},expression:"payload.firstName"}}),_c('CInput',{attrs:{"placeholder":"Last Name *","error-message":_vm.$errorMessage(_vm.$v.payload.lastName, 'lastName'),"error-show":!!_vm.$errorMessage(_vm.$v.payload.lastName, 'lastName')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('IconProfile')]},proxy:true}],null,true),model:{value:(_vm.payload.lastName),callback:function ($$v) {_vm.$set(_vm.payload, "lastName", $$v)},expression:"payload.lastName"}}),_c('CInput',{attrs:{"placeholder":"Email *","error-message":_vm.$errorMessage(_vm.$v.payload.email, 'email'),"error-show":!!_vm.$errorMessage(_vm.$v.payload.email, 'email')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('IconMail')]},proxy:true}],null,true),model:{value:(_vm.payload.email),callback:function ($$v) {_vm.$set(_vm.payload, "email", $$v)},expression:"payload.email"}}),_c('FormSelect',{attrs:{"error":_vm.$errorMessage(_vm.$v.payload.role, 'role'),"error-show":!!_vm.$errorMessage(_vm.$v.payload.role, 'role'),"label":"name","options":_vm.roleList,"placeholder":"Role"},scopedSlots:_vm._u([{key:"dropdown-menu",fn:function(ref){
var context = ref.context;
var api = ref.api;
return [_c('div',{staticClass:"bg-white flex flex-col space-y-2 pt-8 pb-4 max-h-300"},_vm._l((context.filteredOptions),function(option){return _c('div',{key:api.getOptionKey(option),staticClass:"flex items-center cursor-pointer",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();api.selectable(option) ? api.select(option) : null}}},[_c('div',{staticClass:"flex flex-shrink-0 justify-center items-center w-8 h-8 ml-2"},[(api.isOptionSelected(option))?_c('IconDone',{staticClass:"w-6 h-6 text-green-100"}):_vm._e()],1),_c('span',{staticClass:"ml-1 pr-2 text-body3 whitespace-nowrap",class:{
                    'text-secondary44': !api.isOptionSelected(option),
                    'text-primary': !api.isOptionSelected(option),
                  }},[_vm._v(_vm._s(option.name))])])}),0)]}}],null,true),model:{value:(_vm.payload.role),callback:function ($$v) {_vm.$set(_vm.payload, "role", $$v)},expression:"payload.role"}})],1),_c('div',{staticClass:"separator my-10"}),_c('div',{staticClass:"flex justify-end space-x-3"},[_c('FormButton',{staticClass:"text-gray-500",attrs:{"type":"outline","accent":"secondary"},on:{"click":hide}},[_vm._v(" Cancel ")]),_c('FormButton',{staticClass:"text-white",on:{"click":_vm.onSend}},[(_vm.$wait.is('update.user.create'))?[_vm._v(" Loading ")]:[_vm._v(" Send ")]],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }