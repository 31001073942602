<template>
  <VueNotifications animation-name="v-fade-left" width="100%" :ignore-duplicates="true">
    <template slot="body" slot-scope="props">
      <div
        :class="[
          { 'bg-red-100 text-white': props.item.type === 'error' },
          { 'bg-green-100': props.item.type === 'success' },
        ]"
        class="flex items-center space-x-2 py-2 px-18 font-medium text-xs cursor-pointer"
        @click="props.close"
      >
        <IconDone v-if="props.item.type === 'success'" class="w-4 h-4" />
        <IconClose v-else class="w-4 h-4" />
        <div>
          <span v-if="props.item.title">
            {{ props.item.title }}
          </span>
          <span v-if="props.item.text">
            {{ props.item.text }}
          </span>
        </div>
      </div>
    </template>
  </VueNotifications>
</template>

<script>
import { IconClose, IconDone } from '@/components/icons';

export default {
  name: 'Notification',
  components: {
    IconDone,
    IconClose,
  },
};
</script>

<style>
.v-fade-left-leave-active,
.v-fade-left-enter-active {
  transition: 0.1s;
}
.v-fade-left-enter {
  transform: translate(100%, 0);
}

.v-fade-left-leave-to {
  transform: translate(100%, 0);
}
</style>
