<template>
  <div class="bg-black-100 text-white flex flex-col p-5 space-y-8">
    <Tippy>
      <template #trigger="{ shown }">
        <div class="rounded-15 bg-gray-600 px-4 py-3 cursor-pointer">
          <div class="flex flex-row items-center space-x-4">
            <div class="flex flex-col flex-grow">
              <template v-if="user">
                <span class="text-sm font-semibold"> {{ user.first_name }} {{ user.last_name }} </span>
                <span class="text-xs">
                  {{ getUserRole }}
                </span>
              </template>
            </div>
            <div class="w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
              <div class="w-full h-full bg-opacity-25 bg-white" />
            </div>
            <div>
              <IconDropdownDown
                class="transform transition-transform duration-100"
                :class="{
                  'rotate-180': shown,
                }"
              />
            </div>
          </div>
        </div>
      </template>
      <template #default="{ hide }">
        <div class="py-5">
          <div class="flex flex-col text-primary text-sm leading-5">
            <span
              class="px-12 py-1 cursor-pointer"
              @click="
                () => {
                  hide();
                  toUser();
                }
              "
            >
              {{ $t('Menu.Settings') }}
            </span>
            <span
              class="px-12 py-1 text-red-100 cursor-pointer"
              @click="
                () => {
                  hide();
                  onLogout();
                }
              "
            >
              {{ $t('Menu.Logout') }}
            </span>
          </div>
        </div>
      </template>
    </Tippy>
    <div class="flex flex-col space-y-8">
      <div v-for="(group, groupIndex) in _payload" :key="groupIndex">
        <router-link
          v-if="!!group.parentLink"
          :key="groupIndex"
          :to="{ name: 'Dashboard.Chat' }"
          exact
          class="flex flex-col space-y-4 text-white text-sm menu-parent-link"
        >
          <div class="flex items-center justify-between">
            <span>{{ group.group }}</span>
            <CTag class="menu__tag" accent="secondary" rounded>
              {{ group.addon }}
            </CTag>
          </div>

          <div class="separator" />
        </router-link>

        <MenuGroup v-else :payload="group" />
      </div>
    </div>
  </div>
</template>

<script>
import CTag from 'devotedcg-ui-components-v2/CTag.vue';
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

import { IconDropdownDown } from '@/components/icons';
import MenuGroup from '@/components/Menu/MenuGroup.vue';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'Menu',
  components: {
    MenuGroup,
    Tippy,
    IconDropdownDown,
    CTag,
  },
  computed: {
    ...mapState({ user: (state) => state.auth.user }),
    ...mapGetters({
      unreadMessages: 'streamChat/unreadMessages',
    }),
    payload() {
      return [
        {
          group: this.$t('Menu.Asset requests'),
          addon: this.$t('Settings.Create'),
          can: this.$can('request_flow.new_request'),
          onAddon: 'route',
          data: [
            {
              label: this.$t('Menu.My Requests'),
              to: 'Dashboard.AssetRequests.Me',
              can: this.$can('request.view_requests_that_are_assigned_to_me'),
            },
            {
              label: this.$t('Menu.All Requests'),
              to: 'Dashboard.AssetRequests.All',
              can: this.$can('request.view_all_requests_table'),
            },
          ],
        },
        {
          group: this.$t('Chat.Chat'),
          addon: this.unreadMessages,
          parentLink: true,
          data: [],
        },
        {
          group: this.$t('Menu.Clients'),
          addon: this.$t('Form.Add'),
          can: this.$can('general.invite_clients'),
          onAddon: () => {
            this.setModal(false);
          },
          data: [
            {
              label: this.$t('Menu.Clients Database'),
              to: 'Dashboard.Clients.Database',
              new: 0,
              can: this.$can('general.view_clients'),
            },
          ],
        },
        {
          group: this.$t('Menu.Vendors'),
          addon: this.$t('Form.Add'),
          can: this.$can('general.invite_vendors'),
          onAddon: () => {
            this.$bvModal.show('add-vendor');
          },
          data: [
            {
              label: this.$t('Menu.Vendor Database'),
              to: 'Dashboard.Vendors.Database',
              new: 0,
              can: this.$can('general.view_vendors'),
            },
            {
              label: this.$t('Menu.Portfolio Review'),
              to: 'Dashboard.Vendors.Portfolio',
              new: 0,
            },
            {
              label: this.$t('Vendors Matching.Talent Matching'),
              to: 'Dashboard.Vendors.Portfolio.Vendors.Home.Vendors',
              new: 0,
              can: this.$can('general.view_vendors'),
            },
          ],
        },
        {
          group: this.$t('Menu.Devoted Users'),
          addon: this.$t('Form.Invite'),
          onAddon: () => {
            this.$bvModal.show('user-invitation');
          },
          can: this.$can('general.invite_admin_users'),
          data: [
            {
              label: this.$t('Menu.Devoted Database'),
              to: 'Dashboard.Devoted.Database',
              new: 0,
              can: this.$can('general.view_admin_users'),
            },
          ],
        },
        {
          group: this.$t('Menu.Settings'),
          data: [
            {
              label: this.$t('Menu.Asset Catalogs'),
              to: 'Dashboard.Settings.AssetsCatalogs',
              new: 0,
              can: this.$can('asset_catalog.view_asset_catalog'),
            },
            {
              label: this.$t('Menu.Decision Trees'),
              to: 'Dashboard.Settings.DecisionTrees',
              new: 0,
              can: this.$can('decision_tree.view_decision_tree'),
            },
          ],
        },
        {
          group: this.$t('Menu.Analytics'),
          data: [
            {
              label: this.$t('Menu.Vendor Reports'),
              to: 'Dashboard.Analytics.Reports.Vendors',
              new: 0,
              can: this.$can('general.view_analytics'),
            },
            {
              label: this.$t('Menu.Samples Reports'),
              to: 'Dashboard.Analytics.Reports.Samples',
              new: 0,
              can: this.$can('general.view_analytics'),
            },
            {
              label: this.$t('Menu.Qualification Reports'),
              to: 'Dashboard.Analytics.Reports.Qualification',
              new: 0,
              can: this.$can('general.view_analytics'),
            },
          ],
        },
      ];
    },
    _payload() {
      const withDataFiltered = cloneDeep(this.payload).map((group) => {
        group.data = group.data.filter((item) => !Object.prototype.hasOwnProperty.call(item, 'can') || item.can);
        return group;
      });

      return withDataFiltered.filter((group) => {
        const addonCan = group.hasOwnProperty.call(group, 'can') && group.can;

        return group.data.length || addonCan || group.hasOwnProperty.call(group, 'parentLink');
      });
    },

    getUserRole() {
      return this.user?.role?.name;
    },
  },
  methods: {
    ...mapActions({
      setModalMode: 'client/setModalMode',
      setStatus: 'client/setStatus',
      logout: 'auth/logout',
      initializeChat: 'streamChat/initializeChat',
      destroyChat: 'streamChat/destroyChat',
    }),
    onLogout() {
      this.destroyChat();

      this.logout();
    },
    toUser() {
      this.$router.push({
        name: 'Dashboard.Devoted.Database.User',
        params: {
          id: this.user.id,
        },
      });
    },
    setModal(mode) {
      this.setModalMode({
        isEditableModal: mode,
        id: null,
        from: '',
      });

      this.$bvModal.show('client-invitation');
    },
  },
  watch: {
    'user.id': {
      handler() {
        this.initializeChat();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  &-parent-link {
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: 0.00625rem;
    text-decoration: none;
    color: #f9fafc;

    cursor: pointer;

    &.router-link-active,
    &:hover {
      color: var(--Styles-Text-Purple-L-500, #8f8be5);
    }
  }

  &__tag {
    border-radius: 100px;
  }
}
</style>
