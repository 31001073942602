<template>
  <ModalBase name="client-invitation" @hide="onHide">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-2">
        <h2 class="text-3xl font-semibold">
          {{ $t('Clients.Client basic info') }}
        </h2>
      </div>
      <div class="separator my-10" />
      <div class="flex flex-col space-y-6">
        <CInput
          v-model="payload.firstName"
          placeholder="First Name *"
          :error-message="$errorMessage($v.payload.firstName, 'firstName')"
          :error-show="!!$errorMessage($v.payload.firstName, 'firstName')"
        >
          <template #prepend>
            <IconProfile />
          </template>
        </CInput>
        <CInput
          v-model="payload.lastName"
          placeholder="Last Name *"
          :error-message="$errorMessage($v.payload.lastName, 'lastName')"
          :error-show="!!$errorMessage($v.payload.lastName, 'lastName')"
        >
          <template #prepend>
            <IconProfile />
          </template>
        </CInput>
        <CInput
          v-model="payload.emailLocal"
          placeholder="Email *"
          :disabled="localModalData.isEditableModal"
          :error-message="$errorMessage($v.payload.emailLocal, 'email')"
          :error-show="!!$errorMessage($v.payload.emailLocal, 'email')"
        >
          <template #prepend>
            <IconMail />
          </template>
        </CInput>
        <CInput
          v-model="payload.company"
          placeholder="Company *"
          :error-message="$errorMessage($v.payload.company, 'company')"
          :error-show="!!$errorMessage($v.payload.company, 'company')"
        >
          <template #prepend>
            <IconBuilding />
          </template>
        </CInput>
      </div>
      <div class="separator my-10" />
      <div class="flex justify-end space-x-3">
        <FormButton type="outline" accent="secondary" class="text-gray-500" @click="hide">
          {{ $t('Form.Cancel') }}
        </FormButton>
        <FormButton class="text-white" @click="onSend">
          <template v-if="$wait.is(actionWaiter)">
            {{ $t('Devoted.Loading') }}
          </template>
          <template v-else>
            {{ addEditButton }}
          </template>
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import { IconBuilding, IconMail, IconProfile } from '@/components/icons';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalClientInvitation',

  components: {
    ModalBase,
    CInput,
    FormButton,
    IconProfile,
    IconMail,
    IconBuilding,
  },

  data() {
    return {
      localClientInfo: null,
      localModalData: {
        isEditableModal: false,
        id: null,
        from: '',
      },
      payload: {
        firstName: '',
        lastName: '',
        emailLocal: '',
        company: '',
      },
    };
  },

  computed: {
    ...mapState('client', ['clientList', 'clientInfo', 'setModalMode']),
    actionWaiter() {
      const { id, isEditableModal } = this.localModalData;
      return isEditableModal ? `update.client.${id}` : 'createClient';
    },

    addEditButton() {
      const isEditmode = this.localModalData.isEditableModal;
      return isEditmode ? this.$t('Asset requests.Save') : this.$t('Form.Add');
    },
  },

  watch: {
    setModalMode: {
      handler(newData) {
        this.localModalData = newData;
        this.watchStateHandler(this.localModalData);
      },

      immediate: true,
    },
  },

  validations: {
    payload: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      emailLocal: {
        required,
        email,
      },
      company: {
        required,
      },
    },
  },

  methods: {
    ...mapActions('client', ['postClientCreate', 'putClientInfo', 'getClientList', 'fetchClientInfo']),
    ...mapActions('error', ['clearError']),

    onSend() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.clearValidations();
    },

    clearValidations() {
      this.$v.$reset();
      this.clearError();

      const newClientInfo = this.setClientInfo(this.payload);

      if (!this.localModalData.isEditableModal) {
        this.postClientCreate(newClientInfo).then(() => {
          this.handleAddResponse();
        });
        return;
      }

      this.putClientInfo(newClientInfo).then(() => {
        this.handleEditResponse(this.localModalData.from);
      });
    },

    clearForm() {
      Object.keys(this.payload).forEach((key) => {
        this.payload[key] = '';
      });
    },

    onHide() {
      this.clearForm();
      this.clearError();
      this.$v.$reset();
    },

    setPayload(data) {
      const isList = this.localModalData.from === 'list';
      return {
        firstName: data.first_name,
        lastName: data.last_name,
        emailLocal: data.email,
        company: (isList && data?.company) || data?.profile?.company,
      };
    },

    setClientInfo({ firstName, lastName, company, emailLocal }) {
      return {
        first_name: firstName,
        last_name: lastName,
        company_name: company,
        ...((this.localModalData.id && { id: this.localModalData.id }) || {
          email: emailLocal,
        }),
      };
    },

    handleEditResponse(from) {
      this.$notify.success({
        text: this.$t('Client.The item was edited'),
      });

      const getModal = {
        client: () => this.fetchClientInfo(this.localModalData.id),
        list: () =>
          this.getClientList({
            page: this.clientList.currentPage,
            perPage: this.clientList.perPage,
            show_deleted: 1,
          }),
      };

      getModal[from]();
      this.clearForm();
      this.$bvModal.hide('client-invitation');
    },

    handleAddResponse() {
      this.$notify.success({
        text: this.$t('Client.The item was created'),
      });

      this.getClientList({
        page: this.clientList.currentPage,
        perPage: this.clientList.perPage,
        show_deleted: 1,
      });

      this.$bvModal.hide('client-invitation');
    },

    watchStateHandler(data) {
      if (!data.isEditableModal) {
        return;
      }

      const infoOptions = {
        client: this.clientInfo[this.localModalData.id],
        list: this.clientList.data.find(({ id }) => id === this.localModalData.id),
      };

      this.localClientInfo = infoOptions[data.from];

      this.payload = this.setPayload(this.localClientInfo);
    },
  },
};
</script>
