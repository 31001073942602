<template>
  <div class="flex flex-col space-y-4">
    <div class="flex flex-row items-center justify-between">
      <span class="cursor-default menu-parent-link">
        {{ payload.group }}
      </span>
      <span
        v-if="_payload.addon && _payload.can && _payload.onAddon !== 'route'"
        :class="[
          'text-xs font-semibold py-1 px-4',
          'border cursor-pointer rounded-10',
          'hover:text-blue-200 hover:border-blue-200',
          'transition-colors duration-100',
        ]"
        @click="onAddonClick"
      >
        + {{ _payload.addon }}
      </span>
      <router-link
        v-else-if="_payload.addon && _payload.can && _payload.onAddon === 'route'"
        :to="{ name: 'Dashboard.Request.Create' }"
        :class="[
          'router-custom-link',
          'text-xs font-semibold py-1 px-4',
          'border cursor-pointer rounded-10',
          'hover:text-blue-200 hover:border-blue-200',
          'transition-colors duration-100',
        ]"
      >
        + {{ _payload.addon }}
      </router-link>
    </div>
    <div class="separator" />
    <div class="flex flex-col space-y-2">
      <router-link
        v-for="obj in _payload.data"
        :key="obj.label"
        :to="$router.resolve({ name: obj.to }).href"
        class="flex"
      >
        <template #default="{ navigate, isActive, href }">
          <a
            :href="href"
            class="flex-grow flex flex-row items-center space-x-2 group cursor-pointer no-underline select-none"
            @click="navigate"
          >
            <div class="w-6 h-6">
              <IconBell v-if="obj.new" class="w-full h-full" />
            </div>
            <span
              class="text-sm group-hover:text-white transition-colors duration-100"
              :class="[
                {
                  'font-medium text-secondary': !isActive,
                  'text-white font-bold': isActive,
                },
              ]"
            >
              {{ obj.label }}
            </span>
          </a>
        </template>
      </router-link>
    </div>
  </div>
</template>

<script>
import { IconBell } from '@/components/icons';

export default {
  name: 'MenuGroup',
  components: {
    IconBell,
  },
  props: {
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _payload() {
      return {
        ...this.payload,
        data: this.payload.data.map((obj) => ({
          ...obj,
          active: obj.to && this.$route.name.includes(obj.to),
        })),
      };
    },
  },
  methods: {
    onAddonClick() {
      if (this.payload.onAddon && typeof this.payload.onAddon === 'function') {
        this.payload.onAddon();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu-parent-link {
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
  text-decoration: none;
  color: #f9fafc;
}

.router-custom-link {
  color: #ffffff;
  text-decoration: none;
  &:hover {
    color: rgba(0, 98, 255, var(--text-opacity));
  }
}
</style>
