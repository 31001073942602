<template>
  <ModalBase name="user-invitation" @hide="onHide">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-2">
        <h2 class="text-3xl font-semibold">
          {{ $t('Invitation.Admin invitation') }}
        </h2>
        <div class="text-gray-400 text-xs text-center">
Send invitation to admin
</div>
      </div>
      <div class="separator my-10" />
      <div class="flex flex-col space-y-6">
        <CInput
          v-model="payload.firstName"
          placeholder="First Name *"
          :error-message="$errorMessage($v.payload.firstName, 'firstName')"
          :error-show="!!$errorMessage($v.payload.firstName, 'firstName')"
        >
          <template #prepend>
            <IconProfile />
          </template>
        </CInput>
        <CInput
          v-model="payload.lastName"
          placeholder="Last Name *"
          :error-message="$errorMessage($v.payload.lastName, 'lastName')"
          :error-show="!!$errorMessage($v.payload.lastName, 'lastName')"
        >
          <template #prepend>
            <IconProfile />
          </template>
        </CInput>
        <CInput
          v-model="payload.email"
          placeholder="Email *"
          :error-message="$errorMessage($v.payload.email, 'email')"
          :error-show="!!$errorMessage($v.payload.email, 'email')"
        >
          <template #prepend>
            <IconMail />
          </template>
        </CInput>
        <FormSelect
          v-model="payload.role"
          :error="$errorMessage($v.payload.role, 'role')"
          :error-show="!!$errorMessage($v.payload.role, 'role')"
          label="name"
          :options="roleList"
          placeholder="Role"
        >
          <template #dropdown-menu="{ context, api }">
            <div class="bg-white flex flex-col space-y-2 pt-8 pb-4 max-h-300">
              <div
                v-for="option in context.filteredOptions"
                :key="api.getOptionKey(option)"
                class="flex items-center cursor-pointer"
                @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
              >
                <div class="flex flex-shrink-0 justify-center items-center w-8 h-8 ml-2">
                  <IconDone v-if="api.isOptionSelected(option)" class="w-6 h-6 text-green-100" />
                </div>
                <span
                  :class="{
                    'text-secondary44': !api.isOptionSelected(option),
                    'text-primary': !api.isOptionSelected(option),
                  }"
                  class="ml-1 pr-2 text-body3 whitespace-nowrap"
                  >{{ option.name }}</span>
              </div>
            </div>
          </template>
        </FormSelect>
      </div>
      <div class="separator my-10" />
      <div class="flex justify-end space-x-3">
        <FormButton type="outline" accent="secondary" class="text-gray-500" @click="hide">
Cancel
</FormButton>
        <FormButton class="text-white" @click="onSend">
          <template v-if="$wait.is('update.user.create')">
Loading
</template>
          <template v-else>
Send
</template>
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import FormSelect from '@/components/Form/FormSelect.vue';
import { IconDone, IconMail, IconProfile } from '@/components/icons';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalUserInvitation',
  components: {
    ModalBase,
    CInput,
    FormButton,
    IconProfile,
    FormSelect,
    IconMail,
    IconDone,
  },
  data() {
    return {
      payload: {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
      },
    };
  },
  validations: {
    payload: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      role: {
        required,
      },
    },
  },
  computed: {
    ...mapState('role', ['roleList']),
    ...mapState('user', ['userList']),
  },
  methods: {
    ...mapActions('error', ['clearError']),
    ...mapActions('user', ['postUserCreate', 'getUserList']),
    onSend() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.clearValidations();
    },

    clearValidations() {
      this.$v.$reset();
      this.clearError();
      this.postUserCreate({
        first_name: this.payload.firstName,
        last_name: this.payload.lastName,
        email: this.payload.email,
        role_id: this.payload.role.id,
      }).then(() => {
        this.$notify.success({
          text: 'The item was created',
        });
        this.getUserList({
          page: this.userList.currentPage,
          perPage: this.userList.perPage,
        });
        this.$bvModal.hide('user-invitation');
      });
    },

    clearForm() {
      Object.keys(this.payload).forEach((key) => {
        this.payload[key] = '';
      });
    },

    onHide() {
      this.clearForm();
      this.clearError();
      this.$v.$reset();
    },
  },
};
</script>
