var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-4"},[_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('span',{staticClass:"cursor-default menu-parent-link"},[_vm._v(" "+_vm._s(_vm.payload.group)+" ")]),(_vm._payload.addon && _vm._payload.can && _vm._payload.onAddon !== 'route')?_c('span',{class:[
        'text-xs font-semibold py-1 px-4',
        'border cursor-pointer rounded-10',
        'hover:text-blue-200 hover:border-blue-200',
        'transition-colors duration-100' ],on:{"click":_vm.onAddonClick}},[_vm._v(" + "+_vm._s(_vm._payload.addon)+" ")]):(_vm._payload.addon && _vm._payload.can && _vm._payload.onAddon === 'route')?_c('router-link',{class:[
        'router-custom-link',
        'text-xs font-semibold py-1 px-4',
        'border cursor-pointer rounded-10',
        'hover:text-blue-200 hover:border-blue-200',
        'transition-colors duration-100' ],attrs:{"to":{ name: 'Dashboard.Request.Create' }}},[_vm._v(" + "+_vm._s(_vm._payload.addon)+" ")]):_vm._e()],1),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"flex flex-col space-y-2"},_vm._l((_vm._payload.data),function(obj){return _c('router-link',{key:obj.label,staticClass:"flex",attrs:{"to":_vm.$router.resolve({ name: obj.to }).href},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var href = ref.href;
return [_c('a',{staticClass:"flex-grow flex flex-row items-center space-x-2 group cursor-pointer no-underline select-none",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"w-6 h-6"},[(obj.new)?_c('IconBell',{staticClass:"w-full h-full"}):_vm._e()],1),_c('span',{staticClass:"text-sm group-hover:text-white transition-colors duration-100",class:[
              {
                'font-medium text-secondary': !isActive,
                'text-white font-bold': isActive,
              } ]},[_vm._v(" "+_vm._s(obj.label)+" ")])])]}}],null,true)})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }