<template>
  <div class="min-w-screen-lg overflow-auto">
    <div class="h-screen w-full flex flex-row items-stretch">
      <div class="w-80 flex-shrink-0 h-full">
        <Simplebar class="h-full">
          <Menu class="min-h-screen" />
        </Simplebar>
        <ModalClientInvitation />
        <ModalUserInvitation />
        <ModalAddVendor />
      </div>
      <div class="flex-grow overflow-auto flex flex-col bg-gray-100 min-w-screen-lg">
        <LayoutHeader />
        <div class="px-6 py-9 flex flex-col flex-grow">
          <router-view />
          <Notification />
        </div>
      </div>
      <CModalAttachmentPreview />
      <CModalAttachmentRemove />
    </div>
  </div>
</template>

<script>
import 'simplebar/dist/simplebar.min.css';

import CModalAttachmentPreview from 'devotedcg-ui-components/Modal/CModalAttachmentPreview.vue';
import CModalAttachmentRemove from 'devotedcg-ui-components/Modal/CModalAttachmentRemove.vue';
import Simplebar from 'simplebar-vue';

import LayoutHeader from '@/components/Layout/LayoutHeader.vue';
import Menu from '@/components/Menu/Menu.vue';
import ModalAddVendor from '@/components/Modal/ModalAddVendor.vue';
import ModalClientInvitation from '@/components/Modal/ModalClientInvitation.vue';
import ModalUserInvitation from '@/components/Modal/ModalUserInvitation.vue';
import Notification from '@/components/Notification.vue';

export default {
  name: 'DefaultLayout',
  components: {
    Simplebar,
    Menu,
    LayoutHeader,
    ModalClientInvitation,
    Notification,
    ModalUserInvitation,
    ModalAddVendor,
    CModalAttachmentRemove,
    CModalAttachmentPreview,
  },
  data() {
    return {};
  },
};
</script>
